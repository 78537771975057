import { TranscriptElement } from '../../types.ts/transcript';

export default class KaraokeWord {
  private elements: TranscriptElement[];

  constructor(
    public originalElement: TranscriptElement,
    public relativeStart: number,
    public relativeEnd: number,
    public transcriptIndex: number,
  ) {
    this.elements = [originalElement];
  }

  public appendPunctuation(element: TranscriptElement): void {
    this.elements.push(element);
  }

  public getText() {
    return this.elements
      .filter((e) => e.state !== 'muted') // Filter out muted elements
      .map((e) => e.value)
      .join('');
  }

  public getElements(): TranscriptElement[] {
    return this.elements;
  }

  public getEndingIndex(): number {
    const lastElement = this.elements[this.elements.length - 1];
    return lastElement.current_index ?? lastElement.initial_index;
  }
}
