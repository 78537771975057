import React, { useState } from 'react';
import styled from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { observer } from 'mobx-react-lite';
import RepublishOnSaveModal from './RepublishOnSaveModal';
import RetrySavingModal from './RetrySavingModal';

export const SaveButton: React.FC = observer(() => {
  const isSaving = videoCreator.isSaving;
  const isEnabled = videoCreator.isVideoCreatorReady;
  const savingError = videoCreator.savingError;
  const [showRepublishModal, setShowRepublishModal] = useState(false);

  const handleSaveData = async (asFinal: boolean) => {
    if (isSaving) return;
    videoCreator.currentVideo!.punchList =
      videoCreator.punchListManager.punchListItems!;
    const resetTimeline = false;
    const withRenderer = true;
    let showRepublishModal = false;
    if (
      videoCreator.renderingStatus === 'rendering' ||
      videoCreator.renderQueueing
    ) {
      showRepublishModal = true;
    }
    await videoCreator.saveStoryAndVideo(asFinal, withRenderer, resetTimeline);
    if (showRepublishModal) {
      setShowRepublishModal(true);
    }
  };

  return (
    <>
      <Main
        width={32}
        disabled={!isEnabled}
        active={isSaving}
        onClick={async () => {
          await handleSaveData(false);
        }}
      >
        {isSaving ? 'Saving' : 'Save'}
      </Main>
      {showRepublishModal && (
        <RepublishOnSaveModal closeModal={() => setShowRepublishModal(false)} />
      )}
      {savingError && (
        <RetrySavingModal
          closeModal={() => (videoCreator.savingError = undefined)}
        />
      )}
    </>
  );
});

const Main = styled.div.attrs(
  (props: { active: boolean; disabled: boolean; width: number }) => props,
)`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #f2d093;
  color: #f2d093;
  font-size: 12px;
  cursor: pointer;
  margin: 10px 0 10px 5px;
  ${(props) => (props.disabled ? 'opacity: 0.5; cursor: wait;' : '')}
  ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  ${(props) =>
    props.active ? 'background: #17c964; color: #03041a; cursor: wait;' : ''}
`;
