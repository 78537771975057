import { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import { KaraokeConfig } from '../../videoTranscriptionProcessor/types/karaokeTypes';

type Props = {
  modifyProperty: (propertyMap: Partial<KaraokeConfig>) => void;
  strokeWidth: number | string;
  hasStickyBottom?: boolean;
};
const StrokeSelect = forwardRef((props: Props) => {
  const { strokeWidth: _strokeWidth } = props;
  useEffect(() => {
    const parsed = parseStrokeWidth(_strokeWidth);
    setStrokeWidth(parsed.toString());
  }, [_strokeWidth]);
  const [strokeWidth, setStrokeWidth] = useState(
    _strokeWidth?.toString() || '0',
  );
  const [validationMsg, setValidationMsg] = useState<string | null>(null);
  return (
    <StrokeLines hasError={Boolean(validationMsg)}>
      <StrokeLineIcon>
        <StrokeLine
          height={1}
          color={Number(strokeWidth || 0) > 0 ? '#f2d093' : undefined}
        />
        <StrokeLine
          height={2}
          color={Number(strokeWidth || 0) > 0 ? '#f2d093' : undefined}
        />
        <StrokeLine
          height={3}
          color={Number(strokeWidth || 0) > 0 ? '#f2d093' : undefined}
        />
      </StrokeLineIcon>
      <StrokeInput
        type="number"
        value={strokeWidth}
        step={0.1}
        min={0}
        max={2}
        onChange={(e) => {
          setValidationMsg(null);
          const value = e.target.value;
          const num = Number(value);
          if (value && !isNaN(num)) {
            if (num >= 0 && num <= 2) {
              props.modifyProperty({
                stroke_width: `${value} vmin`,
                letter_spacing: getLetterSpacing(value),
              });
              setStrokeWidth(value);
            } else {
              setValidationMsg('Enter value 0-2');
            }
          } else if (value === '') {
            setStrokeWidth('');
          } else {
            setValidationMsg('Enter value 0-2');
          }
        }}
      />
      {validationMsg && <StrokeInputError>{validationMsg}</StrokeInputError>}
    </StrokeLines>
  );
});

const getLetterSpacing = (strokeWidth: string | number) => {
  const num = Number(strokeWidth);
  if (isNaN(num)) return '0%';
  if (num >= 1.5) return '175%';
  if (num >= 1.2) return '150%';
  if (num >= 0.8) return '75%';
  return '0%';
};

export default StrokeSelect;

const parseStrokeWidth = (strokeWidth: number | string) => {
  if (typeof strokeWidth === 'number') return strokeWidth;
  const strokeWidthNum = Number(strokeWidth);
  if (isNaN(strokeWidthNum)) {
    const str = strokeWidth.replace(/\s+/g, '');
    let numStr = str.split('vmin')[0];
    const num = Number(numStr);
    if (isNaN(num)) numStr = str.split('px')[0];
    return num;
  }
  return strokeWidthNum;
};

const StrokeLines = styled.div<{ hasError?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid ${({ hasError }) => (hasError ? '#e74c3c' : '#484848')};
  border-radius: 4px;
  height: 42px;
  box-sizing: border-box;
  padding: 8px;
  position: relative;
`;

const StrokeLineIcon = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StrokeLine = styled.span<{ height?: number; color?: string }>`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.color || '#484848'};
`;

const StrokeInput = styled.input`
  color: #f3e9d7;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 32px;
  background: none;
  border: none;
  outline: none;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
  }
`;

const StrokeInputError = styled.div`
  position: absolute;
  color: #e74c3c;
  font-size: 9px;
  bottom: -18px;
  left: 0;
  right: 0;
`;
