import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  KeyboardEvent,
  MouseEventHandler,
} from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';

import styled, { css } from 'styled-components';
import { usePinch } from '@use-gesture/react';
import {
  KARAOKE_TRACK_NUMBER,
  videoCreator,
} from '../../stores/VideoCreatorStore';
import { Playhead } from './Playhead';
import { TimelineTrack } from './TimelineTrack';
import { Tickmarks } from './Tickmarks';
import { EndMarker } from './EndMarker';
import { ExtraElementData, Story } from '../../types.ts/story';
import VideoIcon from '../../svgs/VideoIcon';
import TimelineAudioIcon from '../../svgs/TimelineAudioIcon';
import TimelinePhotoIcon from '../../svgs/TimelinePhotoIcon';
import CircleIcon from '../../svgs/CircleIcon';
import TimelineControls from './TimelineControls';
import { getDocumentHeight } from '../../utility/general';
import { DraggableCore } from 'react-draggable';
import { LockIcon, TextIcon } from '../common/icons';
import { throttle, isEqual } from 'lodash';
import { ElementState } from '../../renderer/ElementState';
import TimelineContextMenu from './TimelineContextMenu';
import TimelineLogoIcon from '../../svgs/TimelineLogoIcon';
import MuteKaraokeIcon from '../../svgs/MuteKaraokeIcon';

import DebugModal from '../debug/DebugModal';

type Props = {
  story?: Story;
};

const MIN_TIMELINE_SCALE = 0.1;
const PINCH_MULTIPLIER = 1;

export const Timeline: React.FC<Props> = observer((props) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const mouseEnter: MouseEventHandler = (e) => {
    const currentScroll = window.scrollY;
    scrollRef.current?.focus();
    window.scrollTo({ top: currentScroll });
  };

  const mouseLeave = () => {
    scrollRef.current?.blur();
  };

  const duration = videoCreator.duration;

  const tracksDivRef = useRef<HTMLDivElement>(null);
  const animatedRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<number>(0);
  const [isDragging, setIsDragging] = useState(false);
  const timelineMouseDown = useRef(false);
  const initialMouseY = useRef<number | null>(null);
  const initialHeight = useRef<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState<ElementState | null>(
    null,
  );

  const handleElementClick = useCallback((element: ElementState) => {
    setSelectedElement(element);
    setIsModalOpen(true);
  }, []);

  const tracks = React.useMemo(() => {
    let tracksArray = Array.from(videoCreator.tracks?.entries() ?? []);
    tracksArray.reverse();
    if (videoCreator.tracks?.get(KARAOKE_TRACK_NUMBER)) {
      tracksArray = tracksArray.filter(
        ([track, elements]) => track !== KARAOKE_TRACK_NUMBER,
      );
      const videoTrack = tracksArray.findIndex(([track, elements]) =>
        videoCreator.isOriginalVideoElement(elements[0].source),
      );

      // insert at videoTrack position
      tracksArray.splice(videoTrack + 1, 0, [
        KARAOKE_TRACK_NUMBER,
        videoCreator.tracks?.get(KARAOKE_TRACK_NUMBER)!,
      ]);
    }
    return tracksArray;
  }, [videoCreator.tracks]);

  const [maxWidth, setMaxWidth] = useState(700);
  const [offsetY, setOffsetY] = useState(0);

  let fullTimeWidth = duration / videoCreator.timelineScale - 14;
  if (fullTimeWidth < maxWidth - 14) {
    fullTimeWidth = maxWidth - 14;
  }
  let trackWidth: number | undefined =
    duration * videoCreator.timelineScale + 14;
  if (trackWidth < maxWidth - 14) {
    trackWidth = undefined;
  }

  useEffect(() => {
    if (!tracksDivRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      const width = tracksDivRef.current?.clientWidth ?? 700;
      setMaxWidth(width);
    });
    resizeObserver.observe(tracksDivRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  // Initial Timeline Scale
  useEffect(() => {
    if (duration && maxWidth && videoCreator.stateReady) {
      const scale = (maxWidth - 30) / duration;
      runInAction(() => {
        videoCreator.defaultTimelineScale = scale;
        videoCreator.timelineScale = 0.95 * scale;
      });
    }
  }, [maxWidth, videoCreator.stateReady]);

  useEffect(() => {
    startTimeRef.current = videoCreator.time * 1000;
  }, [
    videoCreator.isPlaying,
    videoCreator.smootherTime,
    videoCreator.timelineScale,
    videoCreator.time,
  ]);

  const setAnimatedScrollPos = useCallback(
    (scroll_factor = 0.1) => {
      if (animatedRef.current && ref.current) {
        const time = videoCreator.time;
        const offsetX = ref.current.scrollLeft;
        const visibleTimeRange = [
          offsetX / videoCreator.timelineScale,
          (offsetX + maxWidth) / videoCreator.timelineScale,
        ];
        const scrollThreshold = videoCreator.isPlaying
          ? visibleTimeRange[1] -
            0.1 * (visibleTimeRange[1] - visibleTimeRange[0])
          : visibleTimeRange[1];

        if (
          time < visibleTimeRange[0] ||
          (time > scrollThreshold && visibleTimeRange[1] < duration) ||
          scroll_factor === 0.5
        ) {
          const scrollTo =
            Math.max(
              0,
              time -
                scroll_factor * (visibleTimeRange[1] - visibleTimeRange[0]),
            ) * videoCreator.timelineScale;
          ref.current.scrollLeft = scrollTo;
          animatedRef.current.style.left = `-${ref.current.scrollLeft}px`;
        }
      }
    },
    [videoCreator.isPlaying, maxWidth, duration],
  );

  useEffect(() => {
    setAnimatedScrollPos();
  }, [videoCreator.time]);

  useEffect(() => {
    setAnimatedScrollPos(0.5);
  }, [videoCreator.timelineScale]);

  const updateTimelineScale = useCallback(
    (state: any) => {
      const { origin, offset } = state;

      const newTimelineScale = Math.max(
        Math.min(
          videoCreator.maxTimelineScale,
          offset[0] * PINCH_MULTIPLIER * videoCreator.defaultTimelineScale,
        ),
        MIN_TIMELINE_SCALE,
      );
      runInAction(() => (videoCreator.timelineScale = newTimelineScale));

      if (ref.current) {
        if (maxWidth - duration * newTimelineScale > 0) {
          if (animatedRef.current) {
            animatedRef.current.style.left = `0px`;
          }
          ref.current.scrollLeft = 0;
          return;
        }

        const positionRect = ref.current.getBoundingClientRect();
        const newOffset =
          videoCreator.time * newTimelineScale -
          (positionRect.right - positionRect.left) / 2;
        ref.current.scrollLeft = Math.max(0, newOffset);
      }
    },
    [videoCreator, maxWidth, duration],
  );

  const minPinch =
    (0.95 * (maxWidth - 30)) / duration / videoCreator.defaultTimelineScale;
  const maxPinch = 400 / videoCreator.defaultTimelineScale;

  const bind = usePinch(updateTimelineScale, {
    preventDefault: false,
    scaleBounds: {
      min: minPinch / PINCH_MULTIPLIER,
      max: maxPinch / PINCH_MULTIPLIER,
    },
    rubberband: true,
  });

  const timeDiff = maxWidth - duration * videoCreator.timelineScale;
  const extraTimeWidth = Math.max(0, timeDiff);
  const extraTimeLeft = duration * videoCreator.timelineScale + 14;

  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (ref.current && animatedRef.current) {
      animatedRef.current.style.left = `-${ref.current.scrollLeft}px`;
      setOffsetY(ref.current.scrollTop);
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('scroll', handleScroll, { passive: true });
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleTimelineClick = useCallback(
    async (e: React.MouseEvent<HTMLDivElement>) => {
      const isPlaying = videoCreator.isPlaying;
      const offsetX = ref.current!.scrollLeft;

      if (!videoCreator.isPlayheadDragging && tracksDivRef.current) {
        const elementLeftBoundary =
          tracksDivRef.current.getBoundingClientRect().left;
        const clickPositionRelativeToElement = e.clientX - elementLeftBoundary;
        const clickX = clickPositionRelativeToElement + offsetX;

        const time = (clickX - 14) / videoCreator.timelineScale;
        await videoCreator.setTime(time, true);
        if (isPlaying) videoCreator.renderer?.play();
      }
    },
    [
      videoCreator.isPlaying,
      videoCreator.isPlayheadDragging,
      videoCreator.timelineScale,
      videoCreator.setTime,
    ],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      const frame_rate = 24;

      if (e.key === 'ArrowRight') {
        videoCreator.setTime(videoCreator.time + 1 / frame_rate, true, true);
      } else if (e.key === 'ArrowLeft') {
        videoCreator.setTime(videoCreator.time - 1 / frame_rate, true, true);
      } else if (e.code === 'ShiftLeft' || e.code === 'ShiftRight') {
        videoCreator.setShiftDown(true);
      }
    },
    [videoCreator],
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.code === 'ShiftLeft' || e.code === 'ShiftRight') {
        videoCreator.setShiftDown(false);
      }
    },
    [videoCreator],
  );

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (initialMouseY.current !== null && initialHeight.current !== null) {
      let documentHeight = getDocumentHeight();

      const currentHeight = initialHeight.current * 0.01 * documentHeight;
      const deltaY = initialMouseY.current - e.clientY;

      const updatedHeight = currentHeight + deltaY;
      const updatedHeightPercent = (updatedHeight / documentHeight) * 100;

      const newHeightPercentage = Math.min(
        Math.max(updatedHeightPercent, 10),
        100,
      );

      runInAction(() => {
        videoCreator.timelineHeight = `${newHeightPercentage}%`;
      });
    }
  }, []);

  const throttledHandleMouseMove = useCallback(
    throttle((e: MouseEvent) => {
      handleMouseMove(e);
    }, 100),
    [handleMouseMove],
  );

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!videoCreator.renderer?.ready) return;
      timelineMouseDown.current = true;
      initialMouseY.current = e.clientY;
      initialHeight.current = parseFloat(videoCreator.timelineHeight);
      setIsDragging(true);
    },
    [videoCreator],
  );

  const handleMouseUp = useCallback(() => {
    timelineMouseDown.current = false;
    initialMouseY.current = null;
    initialHeight.current = null;
    setIsDragging(false);
  }, []);

  const trackHeight = React.useMemo(() => {
    return tracks.reduce((acc, curr) => {
      const [_, elements] = curr;
      const isOriginalVideo = videoCreator.isOriginalVideoElement(
        elements[0].source,
      );
      const isText =
        elements[0].source.type === 'text' ||
        elements[0].source.track === KARAOKE_TRACK_NUMBER;
      if (isOriginalVideo) return acc + 99;
      if (isText) return acc + 38;
      return acc + 78;
    }, 20);
  }, [tracks]);

  const activeTracks = React.useMemo(() => {
    return tracks
      .filter(([_, elements]) =>
        elements.some((e) =>
          videoCreator.activeElementIds.includes(e.source.id),
        ),
      )
      .map(([t]) => t);
  }, [tracks, videoCreator.activeElementIds]);

  const getContextSecondaryActions = useCallback(
    (element: ElementState): 'clearVolumeKeyPoints'[] => {
      let result: 'clearVolumeKeyPoints'[] = [];

      if (
        element?.source?.id &&
        (
          videoCreator.currentVideo?.extraElementData[
            element.source?.id
          ] as ExtraElementData
        )?.volumeKeyPoints?.length
      ) {
        result.push('clearVolumeKeyPoints');
      }

      return result;
    },
    [videoCreator],
  );

  const onContextMenu = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      element: ElementState,
    ) => {
      e.preventDefault();
      videoCreator.timelineClipboard = {
        pos: { left: e.clientX, top: e.clientY },
        action: 'copy',
        tmp: { element },
        copied: videoCreator.timelineClipboard?.copied || null,
        secondaryActions: getContextSecondaryActions(element),
      };
    },
    [videoCreator, getContextSecondaryActions],
  );

  const handlePasteContextMenu = useCallback(
    async (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      track: number,
      elements: ElementState[],
    ) => {
      const { action, copied } = videoCreator.timelineClipboard || {};
      if (!action || !copied) return;

      const element = elements[0];
      if (element.source.type !== copied.element.source.type) {
        return;
      }

      const indent = tracksDivRef.current!.getBoundingClientRect().left;
      const posX = e.clientX + ref.current!.scrollLeft - indent;
      const time = (posX - 14) / videoCreator.timelineScale;
      const isTaken = elements.some(
        (e) => time >= e.localTime && time <= e.localTime + e.duration,
      );
      if (isTaken) return;
      e.preventDefault();
      videoCreator.timelineClipboard = {
        ...videoCreator.timelineClipboard!,
        copied: {
          data: { time, track },
          element: videoCreator.timelineClipboard!.tmp.element,
        },
        pos: { left: e.clientX, top: e.clientY },
        action: 'paste',
      };
    },
    [videoCreator],
  );

  return (
    <Main
      tabIndex={-1}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      ref={scrollRef}
      style={{
        minHeight: videoCreator.timelineHeight,
        maxHeight: videoCreator.timelineHeight,
        height: videoCreator.timelineHeight,
      }}
      onClick={() => {
        if (videoCreator.timelineClipboard) {
          videoCreator.timelineClipboard.pos = null;
        }
        if (videoCreator.selectedVolumeKeyPoint) {
          videoCreator.selectVolumeKeyPoint(null);
        }
      }}
    >
      <DraggableCore
        onDrag={(e) => {
          throttledHandleMouseMove(e as unknown as MouseEvent);
        }}
        onStop={handleMouseUp}
        onMouseDown={handleMouseDown}
      >
        <DragButton onMouseUp={handleMouseUp} isDragging={isDragging} />
      </DraggableCore>
      <TimelineControls />
      <Content {...bind()}>
        <TracksLeft>
          <Controls key="control"></Controls>
          <LeftTrackWrapper key="lefttrack">
            <LeftTrackWrapperInner style={{ top: -1 * offsetY }}>
              {tracks.map(([track, elements]) => {
                const isActive = activeTracks.includes(track);
                const isOriginalVideo = videoCreator.isOriginalVideoElement(
                  elements[0].source,
                );
                const isText =
                  elements[0].source.type === 'text' ||
                  elements[0].source.track === KARAOKE_TRACK_NUMBER;
                const isLogo = elements.some((e) =>
                  videoCreator.isLogoElement(e),
                );

                const LeftTrackContent = () => {
                  if (isOriginalVideo) {
                    return (
                      <>
                        <CircleIcon fillColor="#006FEE" />
                        <VideoIcon />
                        <span className="text">Video</span>
                        <LockButton
                          isLocked={videoCreator.frameLockedTracks.includes(
                            track,
                          )}
                          onClick={() =>
                            videoCreator.trackManager.toggleTrackFrameLock(
                              track,
                            )
                          }
                        >
                          <LockIcon fillColor="currentColor" />
                        </LockButton>
                      </>
                    );
                  } else if (isLogo) {
                    return (
                      <>
                        <CircleIcon fillColor="#EFA65D" />
                        <TimelineLogoIcon />
                        <span className="text">Logo</span>
                      </>
                    );
                  } else if (
                    elements.some((e) => videoCreator.isImageElement(e))
                  ) {
                    return (
                      <>
                        <CircleIcon fillColor="#17C964" />
                        <TimelinePhotoIcon />
                        <span className="text">Photo</span>
                        <LockButton
                          isLocked={videoCreator.frameLockedTracks.includes(
                            track,
                          )}
                          onClick={() =>
                            videoCreator.trackManager.toggleTrackFrameLock(
                              track,
                            )
                          }
                        >
                          <LockIcon fillColor="currentColor" />
                        </LockButton>
                      </>
                    );
                  } else if (elements[0].source.type === 'video') {
                    return (
                      <>
                        <CircleIcon fillColor="#006FEE" />
                        <VideoIcon />
                        <span className="text" style={{ whiteSpace: 'nowrap' }}>
                          B-Roll
                        </span>
                        <LockButton
                          isLocked={videoCreator.frameLockedTracks.includes(
                            track,
                          )}
                          onClick={() =>
                            videoCreator.trackManager.toggleTrackFrameLock(
                              track,
                            )
                          }
                        >
                          <LockIcon fillColor="currentColor" />
                        </LockButton>
                      </>
                    );
                  } else if (elements[0].source.type === 'audio') {
                    return (
                      <>
                        <CircleIcon fillColor="#7828C8" />
                        <TimelineAudioIcon />
                        <span className="text">Music</span>
                      </>
                    );
                  } else if (
                    elements[0].source.track === KARAOKE_TRACK_NUMBER
                  ) {
                    return (
                      <>
                        <CircleIcon fillColor="#CBD7F0" />
                        <MuteKaraokeIcon />
                        <span className="text">Karaoke</span>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <CircleIcon fillColor="#CBD7F0" />
                        <TextIcon height="20" width="20" fillColor="#F3E9D7" />
                        <span className="text">Text</span>
                      </>
                    );
                  }
                };

                return (
                  <LeftTrack
                    key={`track-${track}`}
                    linkedVideo={isOriginalVideo}
                    isText={isText}
                    isActive={isActive}
                    onClick={
                      track === KARAOKE_TRACK_NUMBER
                        ? async () => {
                            await videoCreator.setActiveElements();
                            videoCreator.selectedTrack = KARAOKE_TRACK_NUMBER;
                          }
                        : undefined
                    }
                    isLogo={isLogo}
                  >
                    {LeftTrackContent()}
                  </LeftTrack>
                );
              })}
            </LeftTrackWrapperInner>
          </LeftTrackWrapper>
        </TracksLeft>
        <TracksRight ref={tracksDivRef} onClick={handleTimelineClick}>
          <TracksRightInner>
            <TracksRightTop>
              <TracksRightTopInner ref={animatedRef}>
                <Tickmarks maxWidth={maxWidth + extraTimeWidth} />
                <div
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <EndMarker />
                  <Playhead trackHeight={trackHeight} />
                </div>
              </TracksRightTopInner>
            </TracksRightTop>

            <Tracks
              ref={ref}
              style={{ width: fullTimeWidth }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              {tracks.map(([track, elements]) => {
                const isActive = activeTracks.includes(track);
                const isOriginalVideo = videoCreator.isOriginalVideoElement(
                  elements[0].source,
                );
                const isLogo = elements.some((e) =>
                  videoCreator.isLogoElement(e),
                );

                if (isOriginalVideo) {
                  return (
                    <React.Fragment key={track}>
                      <TimelineTrack
                        key={`${track}-video`}
                        elements={elements}
                        story={props.story}
                        isActive={isActive}
                        isOriginalVideo={true}
                        width={trackWidth}
                        onElementCrtlClick={handleElementClick}
                      />
                      <TimelineTrack
                        key={`${track}-audio`}
                        type="linked-audio"
                        elements={elements}
                        story={props.story}
                        isActive={isActive}
                        width={trackWidth}
                        onElementCrtlClick={handleElementClick}
                      />
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={track}>
                      <TimelineContextMenu
                        action="copy"
                        secondaryActions={['clearVolumeKeyPoints']}
                      />
                      <TimelineTrack
                        key={track}
                        track={track}
                        elements={elements}
                        story={props.story}
                        isActive={isActive}
                        onContextMenu={onContextMenu}
                        handlePasteContextMenu={handlePasteContextMenu}
                        isLogo={isLogo}
                        width={trackWidth}
                        onElementCrtlClick={handleElementClick}
                      />
                    </React.Fragment>
                  );
                }
              })}
            </Tracks>
          </TracksRightInner>
        </TracksRight>
      </Content>
      {selectedElement && (
        <DebugModal
          title="Timeline Element Debug"
          content={selectedElement}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedElement(null);
          }}
        />
      )}
    </Main>
  );
});

const Main = styled.div`
  position: relative;
  flex: 1 1 0%;
  background: #030419;
  border-top: 1px solid #333;
  // border-radius: 8px;
  display: flex;
  flex-direction: column;
  &:focus {
    outline: none;
  }
  z-index: 100000;
`;

const DragButton = styled.div<{ isDragging: boolean }>`
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;

  &:hover {
    cursor: row-resize;
    height: 10px;
    background-color: #f3e9d7;
  }
  ${(props) =>
    props.isDragging &&
    css`
      cursor: row-resize;
      height: 10px;
      background-color: #f3e9d7;
    `}
`;

const Content = styled.div`
  position: relative;
  display: flex;
  height: calc(100% - 45px);
  user-select: none;
  overflow: hidden;
  cursor: default;
`;

const TracksLeft = styled.div`
  width: 135px;
  position: relative;
`;

const Controls = styled.div`
  height: 35px;
  padding: 8px 5px 7px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
`;

const LeftTrackWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  // padding-left: 20px;
  height: calc(100% - 35px);
  padding-bottom: 100px;
  box-sizing: border-box;
  overflow: hidden;
`;

const LeftTrack = styled.div<{
  linkedVideo: boolean;
  isText: boolean;
  isActive: boolean;
  isLogo?: boolean;
}>`
  position: relative;
  height: ${(props) => {
    if (props.linkedVideo) return '99px';
    if (props.isText || props.isLogo) return '38px';
    return '78px';
  }};
  ${(props) => (props.isActive ? 'background-color: #48484880;' : '')}
  display: flex;
  gap: 8px;
  padding-left: 32px;
  padding-right: 5px;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  .text {
    color: #f3e9d7;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const TracksRight = styled.div`
  position: relative;
  flex: 1 1 0%;
  height: 100%;
`;

const TracksRightInner = styled.div`
  position: relative;
  flex: 1 1 0%;
  height: 100%;
`;

const TracksRightTop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Tracks = styled.div`
  top: 35px;
  height: calc(100% - 35px);
  position: absolute;
  // padding-left: 14px;
  overflow: auto;
`;

const ExtraTime = styled.div`
  position: absolute;
  top: 0px;
  z-index: 2;
  height: 100%;
  // background: rgba(255, 255, 255, 0.06);
  pointer-events: none;
`;

const TracksRightTopInner = styled.div`
  position: absolute;
  height: 100%;
`;

const LeftTrackWrapperInner = styled.div`
  position: absolute;
  width: 100%;
`;

const LockButton = styled.div<{ isLocked: boolean }>`
  outline: 0;
  border: 1px solid ${(props) => (props.isLocked ? '#F2D093' : 'transparent')};
  background-color: ${(props) => (props.isLocked ? '#F2D093' : 'transparent')};
  border-radius: 2px;
  color: ${(props) => (props.isLocked ? '#03041A' : '#F2D093')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
