import React, { Fragment, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { VolumeKeyPoints } from './VolumeKeyPoints';

type TimelineBrollElementProps = {
  element: ElementState;
  thumbnail: string;
};

const TimelineBrollElement: React.FC<TimelineBrollElementProps> = observer(
  (props) => {
    const [height, setHeight] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (ref.current === null) return;
      setHeight(ref.current?.clientHeight);
    });

    return (
      <Fragment>
        <Container ref={ref}>
          {!props.thumbnail ? (
            <VideoPlayer src={props.element.source.source} />
          ) : null}
          <VolumeKeyPoints
            id={props.element.source.id}
            element={props.element}
            type={props.element.source.type}
            height={height}
          />
        </Container>
      </Fragment>
    );
  },
);

export default TimelineBrollElement;
const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
`;

const VideoPlayer = styled.video`
  height: 100%;
  weight: 100%;
  object-fit: cover;
`;
